<template>
  <!-- APDS:
Tanto para jefe de zona como el  apd,
Si Expediente esta cerrado por CTD. (chkconf24),
Al EDITAR el expediente abre ventana (impresión de esta mañana de color rojo ) donde indica Imposible edición del expediente …
Solicita un campo de texto para la solicitud de apertura.
Botones de Cancelar y Aceptar
ACEPTAR
Guarda en las observaciones de Jefe de Zona o del APD, dependiendo del usuario una línea con el texto.
Esto genera de forma automática un aviso / incidencias. No hace falta hacer nada si se ha puesto que lo genere al introducir cualquier tipo de observaciones.
 

GESTION:
Si el Expediente está cerrado totalmente (chkconfcierre)
Al EDITAR el expediente abre ventana (impresión de esta mañana de color rojo ) donde indica Imposible edición del expediente …
Solicita un campo de texto para la solicitud de apertura.
Botones de Cancelar y Aceptar
ACEPTAR
Guarda en las observaciones de Cierre (chkdef)
¿?Envio Email .. .tengo que preguntar??
 

Si No esta cerrado totalmente (chkcierre), tanto para el ctd como para admon:
Al pulsar sobre el check:
Si esta Cerrado, lanza la ventana de apertura, preguntando motivo de reapertura (combo) y un texto.
Si esta abierto, lanza la ventana de cierre, preguntando motivo de cierre (combo) y un texto.
Se debe controlar que no se puede cerrar el ctd en caso de que existan facturas pendientes.                (aceptada=0).
No se puede cerrar admon si existen facturas aceptadas sin contabilizar.             (aceptada=1 and fra_conta_asi>0)
Los motivos de apertura y cierre están en datos iniciales.
 

En los dos casos ese texto y motivo se reflejan en las observaciones de cierre(chkdef). -->
  <div>
    <v-dialog persistent v-model="dialog" width="50%">
      <div class="Exp_cierreApertura">
        <!-- CABECERA -->

        <base_Header :Entorno="Entorno.header" @onEvent="cancelar">
        </base_Header>

        <!-- CUERPO -->

        <!-- <div class="cab" style="width: 750px">
      PRESUPUESTOS RELACIONADOS CON EL PRESUPUESTO IMPORTADO
    </div> -->
        <v-sheet class="contenedor" :elevation="4">
          <!-- <div v-if="(opcion = 'solicitudApertura')">
      </div> -->          
          <div class="aviso conflex">
            <div class="columna" style="width:40%">
              <v-select
                v-model="tipo"
                v-bind="$select"              
                :items="itemsTipo"              
              ></v-select>            
            </div>                       

            <div class="columna" style="width:60%">               
              <div class="subtitulo">{{ opciones[opcionN].subtitulo }}</div>
              {{ opciones[opcionN].aviso }}
            </div>          

          </div>
          <div>{{ opciones[opcionN].lin2 }}</div>
          <div>{{ opciones[opcionN].lin3 }}</div>

          <div v-if="opcionN>0">
            <v-select
              v-model="motivo"
              v-bind="$select"
              :label="opciones[opcionN].selectLabel"
              :items="opciones[opcionN].items"
              item-value="name"
              item-text="name"
            ></v-select>
          </div>

          <!-- comentario apertura/solicitud -->
          <v-textarea
            v-bind="$textarea"
            v-model="comentario"
            :label="opciones[opcionN].label"
            rows="5"
            no-resize
          >
          </v-textarea>
          <div class="pie" v-if="tipo<'1'">{{ opciones[opcionN].pie }}</div>
          <div>
            <v-btn v-bind="$cfg.btn.busca_with_text" @click="aceptar">
              Aceptar
            </v-btn>

            <v-btn v-bind="$cfg.btn.busca_with_text" @click="cancelar">
              Cancelar
            </v-btn>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
  </div>
</template>



<script>
// -------------------------IMPORTACIONES
import { mixinCtrls } from "@/mixins/mixinCtrls";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
//import { mixinMto } from "@/mixins/mixinMto.js";
//
// -------------------------EXPORTACIONES
export default {
  mixins: [mixinCtrls],
  components: { base_Header },
  props: {
    facturas: {},
    expRecord: {},
    check: { type: String, default: "" },
    opcion: { type: String, default: "" },
    // accion: { type: [Number, String], default: "" },
    // accionRow: { type: Object, default: () => {} },
    // disparo: { type: Boolean, default: false },
    // componenteTipo: { type: String, default: "M" },

    // datos_complementarios:{},
    // auxRecord: { type: Object, default: null },
    //
  },

  //
  //
  //
  // -------------------------DATA

  data() {
    return {
      dialog: true,
    
      opcionN: 1,
      motivo: "",
      comentario: "",
      Entorno: {},

      tipo: '0',

      itemsTipo: [
        { value: '0', text: 'Solicita Reapertura' },
        { value: '1', text: 'Añadir Observaciones' },
        { value: '2', text: 'Cambio Notice/Alarma' },
      ],

      opciones: [
        {
          opcionN: 0,
          opcion: "aperturaAPD",
          titulo: "EXPEDIENTE CERRADO",
          subtitulo: "EDICION NO PERMITIDA",
          aviso: "Introduzca observaciones/incidencias producidas",
          label: "Introduce Texto",
          pie:
            "Se emitirá un AVISO a SUPERVISORAS del CTD para su analisis y posible respuesta o REAPERTURA",
          obsOpcion: "SOLICITA APERTURA:",
        },
        {
          opcionN: 1,
          opcion: "aperturaCTD",
          titulo: "APERTURA EXPEDIENTE",
          subtitulo: "",
          aviso: "Seleccione el motivo e introduzca texto explicativo",
          label: "Texto explicativo",
          selectLabel: "Selecione motivo",
          pie: "",
          obsOpcion: "SOLICITA APERTURA:",
          items: this.$store.state.datos_iniciales.motivos_reapertura,
        },
        {
          opcionN: 2,
          opcion: "cierre",
          titulo: "CIERRE EXPEDIENTE",
          subtitulo: "",
          aviso: "Seleccione el motivo e introduzca texto explicativo",
          label: "Texto explicativo",
          selectLabel: "Selecione motivo",
          pie: "",
          obsOpcion: "CIERRE EXPEDIENTE:",
          items: this.$store.state.datos_iniciales.motivos_cierre,
        },
      ],
    };
  },
  created() {
    // if (this.opcion=='') this.$emit("event_cierreApertura");
    if (this.opcion=='') this.opcion=='aviso';
    if (this.opcion=='aviso') this.opcionN=0;
    if (this.opcion=='apertura') this.opcionN=1;
    if (this.opcion=="cierre") this.opcionN=2;
    
    //if (this.app == 2) this.opcionN = 0;
   // if (this.app == 1 && this.expRecord.chkconfcierre.value == 1)
   //   this.opcionN = 0;
  //  if (this.app == 1 && this.expRecord.chkconfcierre.value == 0)
   //   this.opcionN = 2;
    // console.log(
    //   "cierre", this.opcion,"/",
    //   this.app,
    //   this.opcionN,
    //   this.expRecord,
    //   this.facturas
    // );
    this.ini_data();
  },
  //
  // -------------------------METHODS
  methods: {
    //
    ini_data() {
      this.Entorno = JSON.parse(JSON.stringify(this.$cfg.base.F));
      //
      this.dialog = true;
      // ENTORNO
      //this.Entorno.header.titulo = "TITULO";
      console.log(this.opciones[this.opcionN], this.opciones[this.opcionN].titulo)
      this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));
      this.Entorno.header.titulo = this.opciones[this.opcionN].titulo;
    },

    aceptar() {
      console.log(this.motivo, this.comentario);

      // APD
      if (this.opcionN == 0 && this.comentario == "") {
        this.$root.$alert.open("Debe introducir texto explicativo", "info");
        return;
      }

      // ADMON/CTD (motivo y comentario)
      if (this.opcionN > 0 && (this.motivo == "" || this.comentario == "")) {
        this.$root.$alert.open("Debe introducir motivo y comentarlo", "info");
        return;
      }
      // CHECK FRAS  
      if (this.opcionN == 2 && this.checkFacturas() == false) return;

      //
      this.peticionOK();
    },

    checkFacturas() {
      if (!Array.isArray(this.facturas)) return;

      this.facturas.forEach((pres) => {
        if (pres.aceptada != 1 || pres.fra_conta_asi < 0) {
          this.$root.$alert.open(
            "Cierre no permitido (Existen presupuestos sin aceptar o contabilizar)",
            "error"
          );
          return false;
        }
      });

      return true;
    },

    async peticionOK() {      
      this.motivo=this.motivo>''?"MOTIVO:"+this.motivo:'';
      let obs = (this.tipo>'0' ? this.comentario : this.opciones[this.opcionN].obsOpcion + " "+this.motivo+ " "+ this.comentario);

      if (this.opcionN>0 || this.app=='1') {

          //if (this.app=='1' && this.opcionN<'2' && this.tipo=='0') this.email_apertura(obs);          
          
          // en gestion, si aviso o apertura(solo del ctd o cierre)
          if (this.app=='1' && (this.opcionN=='0' || (this.opcionN=='1' && this.chk!='chkadmon'))) 
            this.email_apertura(obs);

          this.$emit("event_cierreApertura", { tip: this.tipo, obs: obs });
          return;
      }      

      let args = {
        tipo: "fnc",
        accion: "expediente",
        fn_args: {
          accion: "avisos",
          fnt: "aviso",
          exp_id: this.expRecord.id,
          txt: obs,
        }
      }

      //let apiResult = 
      await this.$store.dispatch("ajaxRequest", { args: args });      
      this.$emit("event_cierreApertura", { obs: obs });
    
    },

    cancelar() {
      this.$emit("event_cierreApertura", { obs: "" });      
    },

    //email supervisoras/admon
    async email_apertura(obs) {   
      
      if (this.tipo=='1') obs="AÑADE OBSERVACIONES:<br>"+obs;
      if (this.tipo=='2') obs="CAMBIO NOTICE/ALARMA:<br>"+obs;

      let args = {
        tipo: "fnc",
        accion: "expediente",
        fn_args: {
          accion: "cierre_email",
          tp: "1",
          exp_id: this.expRecord.id,
          txt: obs,
        }
      }      
      //let apiResult = 
      await this.$store.dispatch("ajaxRequest", { args: args });

    },

    // },
    //
    // -------------------------COMPUTED
    //   computed: {
    //    OpcionN() {
    //       if (this.opcion == "solicitudApertura") {
    //         return 0
    //       }
    //       if (this.opcion == "apertura") {
    //         return 1;
    //       }
    //       return 0
    //     },
    //    computed(){
    //    this.Entorno.header.titulo = opcionN
    //   },
    //}
  },
};
</script>

<style scoped>
.subtitulo {
  font-size: 1.5rem;
  color: red;
  text-align: center;
}
.aviso {
  font-size: 1rem;
  text-align: center;
}
.pie {
  font-size: 1rem;
  color: red;
  text-align: center;
  margin: 10px;
}
</style>

